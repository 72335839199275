<template>
  <component :is="'div'" v-if="isEnabled">
    <slot v-bind="action" />
    <slot v-if="isActionPlanned" :name="actionSlotName" v-bind="action" />
  </component>
</template>

<script>
export default {
  name: 'KioskShiftControlAction',
  props: {
    name: {
      type: String,
      required: true
    },
    shiftControl: {
      type: Object,
      required: true
    }
  },
  computed: {
    currentAction() {
      const { action } = this.shiftControl.state.context || {}
      return action
    },
    isEnabled() {
      return Object.keys(this.shiftControl.shiftActions).includes(this.name)
    },
    isActionPlanned() {
      const { action } = this.shiftControl.state.context
      return action ? action.name === this.name : false
    },
    isRunning() {
      return (
        this.isActionPlanned &&
        this.shiftControl.state.matches('action.running')
      )
    },
    isSubmitted() {
      return (
        this.isActionPlanned &&
        this.shiftControl.state.matches('action.success')
      )
    },
    error() {
      return (
        this.isActionPlanned &&
        this.shiftControl.state.matches('action.failure')
      )
    },
    response() {
      if (!this.isActionPlanned) {
        return false
      }

      return this.currentAction.response
    },
    action() {
      return {
        ...this.shiftControl.shiftActions[this.name],
        name: this.name,
        continue: () => this.shiftControl.service.send('SHIFT_ACTION.CONTINUE'),
        cancel: () => this.shiftControl.service.send('SHIFT_ACTION.CANCEL'),
        running: this.isRunning,
        error: this.error,
        submitted: this.isSubmitted,
        response: this.response,
        attrs: {
          'data-heap-id': `action_${this.name}`
        }
      }
    },
    actionSlotName() {
      return this.shiftControl.state
        .toStrings()
        .find((actionName) => actionName.startsWith('action.'))
        .substr('action.'.length)
    },
    shouldByPass() {
      return (state) => {
        if (!this.isActionPlanned) {
          return false
        }

        if (this.actionSlotName !== state) {
          return false
        }

        if (this.$scopedSlots[this.actionSlotName]) {
          return false
        }

        return true
      }
    }
  },
  watch: {
    actionSlotName() {
      if (this.shouldByPass('before')) {
        this.shiftControl.service.send('SHIFT_ACTION.CONTINUE')
      }
      this.$emit(this.actionSlotName)
    }
  }
}
</script>
