<template>
  <KioskGuardBreakManagementRequired>
    <template
      v-if="!shiftControl.state.matches('views.schedule.SCHEDULED')"
      #default
    >
      <KioskShiftSummaryCard
        :shift-summary="shouldDisplayNextShiftSummary"
        :views="['default', 'alert']"
      >
        <template #default>
          <KioskBreakList
            class="overline responsive"
            :break-compliance="shouldDisplayNextShiftSummary.breakCompliance"
            :start-date-time="shouldDisplayNextShiftSummary.startDateTime"
          />
        </template>
        <template #default:button>
          {{ $t('app.kiosk.seeDetails') }}
        </template>
      </KioskShiftSummaryCard>
    </template>
    <template v-else #default>
      <KioskShiftSummaryCard
        :shift-summary="shouldDisplayNextShiftSummary"
        :views="['alert']"
        class="fill-height"
      />
    </template>
    <template #rejected>
      <KioskShiftSummaryCard
        :shift-summary="shouldDisplayNextShiftSummary"
        :views="['alert']"
        class="fill-height"
      />
    </template>
  </KioskGuardBreakManagementRequired>
</template>

<script>
import KioskGuardBreakManagementRequired from '@/guards/components/KioskGuardBreakManagementRequired'
import KioskShiftSummaryCard from '@/components/KioskShiftSummaryCard'
import KioskBreakList from '@/components/KioskBreakList'

export default {
  name: 'KioskShiftControlCardSummary',
  components: {
    KioskGuardBreakManagementRequired,
    KioskShiftSummaryCard,
    KioskBreakList
  },
  props: {
    shiftControl: {
      type: Object,
      required: true
    }
  },
  computed: {
    shouldDisplayNextShiftSummary() {
      if (this.shiftControl.shiftSummary.isEmpty) {
        return this.shiftControl.nextShiftSummary
      }
      return this.shiftControl.shiftSummary
    }
  }
}
</script>
<style scoped>
@media (orientation: landscape) and (min-height: 800px) {
  .responsive {
    height: 43vh;
  }
}
@media (orientation: landscape) and (max-height: 800px) {
  .responsive {
    height: 28vh;
  }
}
</style>
