<template>
  <VDialog v-model="show" v-bind="dialogProps" v-on="$listeners">
    <VCard :data-heap-id="`dialog_${name}`" tile>
      <VCardText>
        <VContainer>
          <VRow>
            <VCol cols="auto">
              <KioskIconSvg
                class="pt-4"
                :filepath="`/img/icons/${icon}.svg`"
                :fill="color"
                size="50"
              />
            </VCol>
            <VCol cols="10">
              <VRow>
                <VCol cols="11">
                  <slot name="title">
                    <span v-if="title" v-text="title" />
                  </slot>
                </VCol>
                <VCol cols="1">
                  <VBtn
                    v-if="shouldDisplayClose"
                    icon
                    text
                    :data-heap-id="`dialog_${name}_close`"
                    @click="$close"
                  >
                    <VIcon v-text="'close'" />
                  </VBtn>
                </VCol>
              </VRow>
              <VRow>
                <VCol>
                  <slot>
                    <span v-if="text" v-text="text" />
                  </slot>
                </VCol>
              </VRow>
            </VCol>
          </VRow>
          <slot name="extension" />
        </VContainer>
      </VCardText>
      <VCardActions v-if="shouldDisplayActions" class="pa-4">
        <slot name="actions" v-bind="actions">
          <VRow class="mx-2">
            <VCol cols="6" class="pr-4">
              <VBtn
                v-if="cancel"
                :data-heap-id="`dialog_${name}_cancel`"
                color="error"
                block
                large
                :disabled="loading"
                @click="actions.cancel"
                v-text="cancelText"
              />
            </VCol>
            <VCol cols="6" class="pl-4">
              <VBtn
                v-if="confirm"
                color="primary"
                block
                large
                :disabled="loading"
                :data-heap-id="`dialog_${name}_confirm`"
                @click="actions.confirm"
                v-text="confirmText"
              />
            </VCol>
          </VRow>
        </slot>
      </VCardActions>
    </VCard>
  </VDialog>
</template>

<script>
import KioskIconSvg from './KioskIconSvg'
export default {
  name: 'KioskShiftDialog',
  components: {
    KioskIconSvg
  },
  inheritAttrs: true,
  props: {
    value: {
      type: Object,
      default() {
        return {
          show: false,
          message: null,
          timeout: 3000,
          text: '',
          type: 'default'
        }
      }
    },
    name: {
      type: String,
      required: true
    },
    loading: {
      type: Boolean,
      default: false
    },
    icon: {
      type: String,
      default: 'clock'
    },
    color: {
      type: String,
      default: 'var(--v-primary-base)'
    },
    title: {
      type: String,
      default: undefined
    },
    text: {
      type: String,
      default: undefined
    },
    confirm: {
      type: [Function, Boolean],
      default: false
    },
    confirmText: {
      type: String,
      default() {
        return this.$t('app.kiosk.ok.btn.text')
      }
    },
    cancel: {
      type: [Function, Boolean],
      default: false
    },
    cancelText: {
      type: String,
      default() {
        return this.$t('app.kiosk.cancel.btn.text')
      }
    },
    close: {
      type: [Function, Boolean],
      default() {
        return false
      }
    },
    immediate: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      show: this.value.show || false
    }
  },
  computed: {
    shouldDisplayClose() {
      return this.close !== false && this.loading === false
    },
    shouldDisplayActions() {
      const hasActionSlots = [
        this.$scopedSlots.actions,
        this.$slots.actions
      ].find((slotValue) => slotValue)

      if (hasActionSlots) {
        return true
      }

      return [this.cancel, this.confirm].some((value) => value !== false)
    },
    dialogProps() {
      const { show, ...props } = this.value

      return {
        width: '550px',
        ...this.$attrs,
        ...props
      }
    },
    actions() {
      return {
        close: this.$close.bind(this),
        confirm: this.$confirm.bind(this),
        cancel: this.$cancel.bind(this)
      }
    }
  },
  watch: {
    value({ show }) {
      this.show = show || false
    },
    show() {
      if (!this.show) {
        this.$emit('close')
        return
      }
      this.$emit('open')
    }
  },
  mounted() {
    if (this.immediate) {
      this.show = true
    }
  },
  methods: {
    async $close() {
      if (!this.close) {
        this.show = false
        return
      }
      this.show = await this.close(this.dialogProps)
    },
    async $cancel() {
      await this.cancel(this.dialogProps)
      await this.$close()
    },
    async $confirm() {
      await this.confirm(this.dialogProps)
      await this.$close()
    }
  }
}
</script>
