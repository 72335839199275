<script>
import { get } from 'lodash'

export default {
  name: 'KioskShiftControlOnBreakText',
  inheritAttrs: false,
  props: {
    shiftControl: {
      type: Object,
      required: true
    },
    path: {
      type: String,
      required: true
    },
    default: {
      type: String,
      default: undefined
    },
    tag: {
      type: String,
      default: 'span'
    }
  },
  computed: {
    dialog() {
      return {
        title: this.$t(
          'app.kiosk.shiftControl.actions.END_BREAK.success.title'
        ),
        body: this.$t('app.kiosk.shiftControl.actions.END_BREAK.success.body')
      }
    },

    title() {
      return this.$t('app.kiosk.shiftControl.actions.END_BREAK.greeting')
    }
  },
  render(h) {
    const content = this.path
      .split('.')
      .reduce((content, path) => get(content, path, this.default), this)

    if (this.$scopedSlots.default) {
      return this.$scopedSlots.default({ text: content })
    }

    return h(this.tag, this.$attrs, [content])
  }
}
</script>
