<template>
  <VContainer
    fluid
    class="fill-height pt-0"
    data-heap-id="kiosk_control_card_scheduled"
  >
    <VRow class="layout wrap fill-height" align="stretch">
      <VCol :cols="12" :md="4" :lg="3" order="1" class="pr-0 py-0">
        <KioskShiftControlCardSummary :shift-control="shiftControl" />
      </VCol>
      <VCol cols="12" :md="8" :lg="9" order="2" class="py-0">
        <KioskShiftControlActionScheduled
          v-if="shiftControl.state.matches('views.schedule.SCHEDULED')"
          :shift-control="shiftControl"
          @completed="$emit('completed')"
        />
        <KioskShiftControlActionScheduled
          v-if="shiftControl.state.matches('views.schedule.LATE')"
          :shift-control="shiftControl"
          @completed="$emit('completed')"
        />
        <KioskShiftControlActionScheduled
          v-if="shiftControl.state.matches('views.schedule.EARLY_CLOCK_OUT')"
          :shift-control="shiftControl"
          @completed="$emit('completed')"
        />
        <KioskShiftControlActionInProgress
          v-if="shiftControl.state.matches('views.schedule.IN_PROGRESS')"
          :shift-control="shiftControl"
          @completed="$emit('completed')"
        />
        <KioskShiftControlActionOnBreak
          v-if="shiftControl.state.matches('views.schedule.ON_BREAK')"
          :shift-control="shiftControl"
          @completed="$emit('completed')"
        />
      </VCol>
    </VRow>
  </VContainer>
</template>

<script>
import KioskShiftControlCardSummary from './KioskShiftControlCardSummary'
import KioskShiftControlActionScheduled from './KioskShiftControlActionScheduled'
import KioskShiftControlActionInProgress from './KioskShiftControlActionInProgress'
import KioskShiftControlActionOnBreak from './KioskShiftControlActionOnBreak'

export default {
  name: 'KioskShiftControlCardScheduled',
  components: {
    KioskShiftControlCardSummary,
    KioskShiftControlActionScheduled,
    KioskShiftControlActionInProgress,
    KioskShiftControlActionOnBreak
  },
  props: {
    shiftControl: {
      type: Object,
      required: true
    }
  }
}
</script>
