<template>
  <VSimpleTable fixed-header :height="height" class="border">
    <template #default>
      <thead>
        <tr>
          <th></th>
          <th
            v-t="'app.kiosk.shiftComplianceTable.headers.type'"
            class="text-left"
          />
          <th
            v-t="'app.kiosk.shiftComplianceTable.headers.scheduled'"
            class="text-left"
          />
          <th
            v-t="'app.kiosk.shiftComplianceTable.headers.actual'"
            class="text-left"
          />
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(item, i) in resume"
          :key="`${item.type}:${i}`"
          :data-id="item.type"
          :class="{ 'has-warning': hasWarning(item) }"
        >
          <td>
            <KioskIconSvg
              :filepath="`/img/icons/${item.icon}.svg`"
              fill="#2c59cd"
              size="25"
            />
          </td>
          <td class="font-weight-medium">
            <span v-t="`app.kiosk.shiftComplianceTable.types.${item.type}`" />
          </td>
          <td v-text="item.scheduled" />
          <td
            :class="{
              'has-warning': hasWarning(item),
              'error--text': hasWarning(item)
            }"
          >
            <v-icon
              v-if="hasWarning(item)"
              :class="{ 'error--text': hasWarning(item) }"
              v-text="'warning'"
            />
            <span v-if="hasWarning(item)" v-text="item.actual" />
            <span v-else class="primary--text" v-text="item.actual" />
          </td>
        </tr>
      </tbody>
    </template>
  </VSimpleTable>
</template>

<script>
import moment from 'moment'
import KioskIconSvg from '@/components/KioskIconSvg'
import { ShiftBreakSessionTreahold } from '@/lib/kiosk/shift/shift-break-compliance'

export default {
  name: 'KioskShiftComplianceTable',
  components: {
    KioskIconSvg
  },
  inheritAttrs: false,
  props: {
    height: {
      type: String,
      default: '250px'
    },
    shift: {
      type: Object,
      required: true
    },
    threshold: {
      type: Function,
      default(computedSessionItem) {
        const { breakCompliance, startGracePeriodInMinutes } = this.shift

        const treahold = new ShiftBreakSessionTreahold(breakCompliance, {
          startGracePeriodInMinutes: startGracePeriodInMinutes,
          breakGracePeriodStart:
            breakCompliance.breakGracePeriods.gracePeriodStart,
          breakGracePeriodEnd: breakCompliance.breakGracePeriods.gracePeriodEnd,
          extraBreakMinutesTolerance:
            breakCompliance.breakGracePeriods.extraBreakMinutesTolerance
        })

        return treahold.isCompliant(computedSessionItem) === false
      }
    }
  },

  computed: {
    hasWarning() {
      return (computedSessionItem) => {
        return this.threshold({
          type: computedSessionItem.type,
          ...computedSessionItem.$ref
        })
      }
    },
    formatDate() {
      return (date) => {
        return moment(date).format('hh:mm a')
      }
    },
    humanizeDuration() {
      return (durationValue, durationType = 'minutes', format = 'mm') => {
        const value = moment.duration(durationValue, durationType).minutes()
        return this.$tc('app.kiosk.shiftControl.breakInMinutesInteger', value)
      }
    },
    resume() {
      const { breakCompliance } = this.shift

      const clockInRef = breakCompliance.computedSessions.clockIn
      const clockOutRef = breakCompliance.computedSessions.clockOut
      const mealBreakRef = breakCompliance.computedSessions.mealBreak
      const restBreakRef = breakCompliance.computedSessions.restBreak

      return [
        {
          $ref: clockInRef,
          icon: 'clockin',
          type: 'clockin',
          scheduled: this.formatDate(clockInRef.expected),
          actual: this.formatDate(clockInRef.actual)
        },
        {
          $ref: mealBreakRef,
          icon: 'meal',
          type: 'mealbreak',
          scheduled: this.humanizeDuration(mealBreakRef.expectedInMinutes),
          actual: this.humanizeDuration(mealBreakRef.actualInMinutes)
        },
        {
          $ref: restBreakRef,
          icon: 'rest',
          type: 'restbreak',
          scheduled: this.humanizeDuration(restBreakRef.expectedInMinutes),
          actual: this.humanizeDuration(restBreakRef.actualInMinutes)
        },
        {
          $ref: clockOutRef,
          icon: 'clockout',
          type: 'clockout',
          scheduled: this.formatDate(clockOutRef.expected),
          actual: this.formatDate(clockOutRef.actual)
        }
      ]
    }
  }
}
</script>
<style scoped>
.theme--light.v-data-table tbody tr:not(:last-child) td:last-child,
.theme--light.v-data-table
  tbody
  tr:not(:last-child)
  td:not(.v-data-table__mobile-row) {
  border-bottom: 1px solid rgba(0, 0, 0, 0);
}
.v-alert--outlined {
  border: transparent !important;
}
table tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.04);
}
</style>
