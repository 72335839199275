<template functional>
  <VCard class="fill-height">
    <VContainer fluid class="fill-height">
      <VRow
        align="start"
        justify="center"
        class="fill-height"
        :style="{ minHeight: props.height }"
      >
        <VCol cols="12">
          <VCard flat class="blue--text">
            <VCardTitle class="text-center">
              <VCardText>
                <h3
                  class="text-center headline"
                  v-text="props.title({ parent })"
                />
              </VCardText>
            </VCardTitle>
          </VCard>
        </VCol>
        <VCol cols="12" align-self="stretch" class="text-center">
          <VAvatar class="elevation-6 " size="195" color="grey">
            <VRow>
              <VCol cols="12" class="pb-0">
                <VIcon size="60" dark v-text="props.icon" />
              </VCol>
              <VCol cols="12" class="pt-0">
                <p
                  key="label white"
                  v-t="`app.kiosk.shiftControl.actions.CLOCK_IN.value`"
                  :class="`text-center mt-2 mb-5 white--text text-uppercase`"
                />
              </VCol>
            </VRow>
          </VAvatar>
        </VCol>
      </VRow>
    </VContainer>
  </VCard>
</template>

<script>
import moment from 'moment'

export default {
  name: 'KioskShiftEarlyClockInCard',
  props: {
    icon: {
      type: String,
      default: 'alarm_off'
    },
    title: {
      type: Function,
      default({ parent }) {
        const nextShift = parent.shiftControl.nextShiftSummary
        const startShift = moment(nextShift.startDateTime)
          .locale(parent.$i18n.locale)
          .toNow('HH:mm:ss')

        return parent.$tc(
          `app.kiosk.shiftControl.actions.Early_CLOCK_IN.success.body`,
          startShift,
          { time: startShift }
        )
      }
    },
    height: {
      type: String,
      default: '350px'
    }
  }
}
</script>
