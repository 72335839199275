<template>
  <VCard v-bind="$attrs" class="fill-height" tile>
    <VRow class="pa-5 pb-0" align="stretch">
      <VCol cols="12" class="pt-3 py-0">
        <KioskClock
          :value="shiftSummary.shift.startDateTime"
          :locale="$i18n.locale"
          :format="'ddd, DD MMMM'"
        />
      </VCol>
      <VCol cols="12" class="pt-2 py-0">
        <VRow no-gutters>
          <VCol cols="12" sm="5">
            <KioskClock
              :value="shiftSummary.shift.startDateTime"
              :format="['hh:mm', 'A']"
              :locale="$i18n.locale"
              :interval="0"
            >
              <template #default="{ time: [hours, daytime] }">
                <span class="headline primary--text" v-text="hours" />
                <span
                  class="captation text-lowercase primary--text"
                  v-text="daytime"
                />
              </template>
            </KioskClock>
          </VCol>
          <VCol cols="12" sm="2" class="pt-2">
            <KioskIconSvg filepath="/img/icons/arrow-sm.svg" class="mx-2" />
          </VCol>
          <VCol cols="12" sm="5" class="text-right">
            <KioskClock
              :value="shiftSummary.shift.endDateTime"
              :format="['hh:mm', 'A']"
              :locale="$i18n.locale"
              :interval="0"
              class=" primary--text"
            >
              <template #default="{ time: [hours, daytime] }">
                <span class="headline primary--text" v-text="hours" />
                <span
                  class="captation text-lowercase primary--text"
                  v-text="daytime"
                />
              </template>
            </KioskClock>
          </VCol>
        </VRow>
      </VCol>
    </VRow>
    <VDivider />
    <VRow align="start" class="px-3 pt-2 pb-0">
      <KioskAddressTile
        v-if="shiftSummary.siteName"
        :icon="'/img/icons/location.svg'"
        :icon-color="'grey'"
        :text="shiftSummary.position.name"
        :address="addressOneLine || 'N/A'"
        fluid
      />
    </VRow>
    <VRow v-if="$slots.default" class="pa-3 pt-0" align="end">
      <slot />
    </VRow>
  </VCard>
</template>

<script>
import KioskGuards from '@/guards'
import KioskClock from './KioskClock'
import KioskAddressTile from './KioskAddressTile'
import KioskIconSvg from './KioskIconSvg'

export default {
  name: 'KioskShiftCard',
  components: {
    KioskClock,
    KioskAddressTile,
    KioskIconSvg,
    ...KioskGuards
  },
  inheritAttrs: false,
  props: {
    shiftSummary: {
      type: Object,
      required: true
    }
  },
  computed: {
    transformToAddressOneLine(value) {
      let orders = [
        'addressLine1',
        'addressLine2',
        'city',
        'state',
        'country',
        'postalCode'
      ]
      return (value) => {
        if (!value) {
          return ''
        }

        if (orders.length === 0) {
          return orders
        }
        const valueOmitted = [null, undefined, '']
        return orders
          .map((partKey) => value[partKey])
          .filter((part) => !valueOmitted.includes(part))
          .join(', ')
      }
    },
    addressOneLine() {
      return this.transformToAddressOneLine(
        this.shiftSummary.position.address.payload
      )
    }
  }
}
</script>
