import Vue from 'vue'
import moment from 'moment'
import onVisibilityChange from './on-change-visibility'

export const increment = () => {
  return (state) => {
    return moment().toDate()
  }
}

export const decrement = () => {
  const ref = new Date()

  return (state) => {
    const diffInSecondsFromNow = moment(ref).diff(new Date(), 's')
    const diffInSecondsFromInitial = moment(ref).diff(state.initial, 's')

    return moment(state.value)
      .clone()
      .subtract(diffInSecondsFromNow - diffInSecondsFromInitial, 's')
      .toDate()
  }
}

const subscribeToTimeChange = (callback) => {
  let intervalId

  let unsubscribe = () => {
    clearInterval(intervalId)
  }

  intervalId = setInterval(() => {
    callback()
  }, 1000)

  return () => unsubscribe()
}

export const useLiveClock = (timeRef = new Date(), direction = increment) => {
  const state = Vue.observable({
    initial: timeRef,
    value: timeRef
  })

  const applyDirection = direction(state.initial)

  const updateClock = () => {
    state.value = applyDirection(state)
  }

  let unsubscribe = subscribeToTimeChange(updateClock)

  onVisibilityChange(() => {
    unsubscribe()
    unsubscribe = subscribeToTimeChange(updateClock)
  })

  return {
    get initial() {
      return state.initial
    },
    get value() {
      return state.value
    },
    destroy() {
      unsubscribe()
    }
  }
}

export default useLiveClock
