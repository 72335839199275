<template functional>
  <VFadeTransition>
    <VCard color="grey lighten-4 py-2 pt-0" flat class="responsive" tile>
      <VRow align="start">
        <VCol :sm="1">
          <VAvatar>
            <VIcon small color="#afafaf" v-text="props.icon" />
          </VAvatar>
        </VCol>
        <VCol :sm="11">
          <VCardTitle primary-title class="caption">
            <slot> </slot>
          </VCardTitle>
        </VCol>
      </VRow>
    </VCard>
  </VFadeTransition>
</template>

<script>
export default {
  name: 'KioskShiftCardAlert',
  props: {
    icon: {
      type: String,
      default: 'info'
    }
  }
}
</script>
<style scoped>
@media (orientation: landscape) and (min-height: 800px) {
  .responsive {
    height: 43vh;
  }
}
@media (orientation: landscape) and (max-height: 800px) {
  .responsive {
    height: 28vh;
  }
}
</style>
