<template>
  <div id="scroll-target" class="overflow-y-auto">
    <VTimeline
      v-if="breakRuleConditionsItems.length > 0"
      dense
      clipped
      align-top
    >
      <template v-for="(item, index) in breakRuleConditionsItems">
        <slot v-bind="{ item, index }">
          <VTimelineItem :key="index" color="white" small>
            <VRow>
              <VCol cols="3" class="pa-0">
                <span
                  v-if="item.offsetEnd !== 0"
                  class="text-capitalize font-regular"
                  v-text="breakRange(item)"
                />
                <span
                  v-else
                  class="text-capitalize font-regular"
                  v-text="breakAt(item)"
                />
              </VCol>
              <VCol class="pa-0 pl-3" cols="5">
                <KioskIconSvg
                  :filepath="iconOfBreakRuleConditionsItem(item)"
                  size="20"
                  class="mr-2"
                />
                <strong v-text="titleOfBreakRuleConditionsItem(item)"></strong>
              </VCol>
              <VCol class="pt-2 pa-0 text-right" cols="3">
                <span v-text="durationOfBreakRuleConditionsItem(item)" />
              </VCol>
            </VRow>
          </VTimelineItem>
        </slot>
      </template>
    </VTimeline>
    <VList v-else>
      <slot name="empty">
        <VListItem class="border">
          <VListItemContent v-t="'app.kiosk.shiftControl.breakListEmpty'" />
        </VListItem>
      </slot>
    </VList>
  </div>
</template>
<script>
import moment from 'moment'
import KioskIconSvg from '@/components/KioskIconSvg'

export default {
  name: 'KioskBreakList',
  components: {
    KioskIconSvg
  },
  inheritAttrs: false,
  props: {
    breakCompliance: {
      type: Object,
      required: true
    },
    startDateTime: {
      type: String,
      required: true
    }
  },
  computed: {
    iconOfBreakRuleConditionsItem() {
      return (item) => `/img/icons/${item.type.toLowerCase()}.svg`
    },
    titleOfBreakRuleConditionsItem() {
      return (item) =>
        this.$t('app.kiosk.shiftControl.break', {
          typeBreak: item.type.toLowerCase()
        })
    },
    breakRange() {
      return (item) =>
        this.$t('app.kiosk.shiftControl.breakRange', {
          startTime: this.getTime(item.offsetStart),
          endTime: this.getTime(item.offsetEnd)
        })
    },
    breakAt() {
      return (item) =>
        this.$t('app.kiosk.shiftControl.breakAt', {
          startTime: this.getTime(item.offsetStart)
        })
    },
    getTime() {
      return (time) => {
        return moment(this.startDateTime)
          .add(time, 'm')
          .format('hh:mm a')
      }
    },
    durationOfBreakRuleConditionsItem() {
      return (item) =>
        this.$tc('app.kiosk.shiftControl.breakInMinutes', item.duration, {
          count: item.duration
        })
    },
    breakRuleConditionsItems() {
      return this.breakCompliance.breakRuleConditions.filter((item) => item)
    }
  }
}
</script>

<style lang="scss">
.v-timeline {
  overflow-x: hidden;
}
.v-timeline--dense .v-timeline-item__body {
  max-width: calc(100% - 80px) !important;
}
.v-timeline-item__divider {
  min-width: 60px !important;
}
.v-timeline--dense:not(.v-timeline--reverse):before {
  left: calc(5px - 1px) !important;
}
.v-timeline--dense .v-timeline-item {
  margin-left: -45px;
}

.v-timeline-item__dot--small {
  height: 10px !important;
  left: calc(50% - 12px) !important;
  width: 10px !important;
  background-color: #e0e0e0 !important;
}
.v-timeline-item__dot--small .v-timeline-item__inner-dot {
  height: 8px !important;
  margin: 1px !important;
  width: 8px !important;
  background-color: white !important;
}
</style>
