<template>
  <VRow class="fill-height" align="stretch">
    <VProgressLinear v-if="fetching" :indeterminate="true" />
    <VCol v-else cols="12" class="pt-0">
      <KioskGuardUserInteractionRequired
        @user-inactif="$router.push(redirectOnInactif)"
      />
      <KioskShiftControlProvider>
        <template #default="shiftControl">
          <KioskShiftControlCard :shift-control="shiftControl" :error="error" />
        </template>
      </KioskShiftControlProvider>
    </VCol>
  </VRow>
</template>

<script>
import useClock from '@/lib/kiosk/utils/use-clock'
import KioskGuards from '@/guards'
import KioskShiftControlProvider from './components/KioskShiftControlProvider'
import KioskShiftControlCard from './components/KioskShiftControlCard'

export default {
  name: 'ShiftControlIndex',
  components: {
    KioskShiftControlProvider,
    KioskShiftControlCard,
    ...KioskGuards
  },
  beforeRouteEnter(to, from, next) {
    next(async (vm) => {
      vm.fetching = true
      vm.$store
        .dispatch('kiosk/fetchScheduleInformation')
        .then((response) => {
          vm.response = response
        })
        .catch((err) => {
          vm.error = err
        })
        .finally(() => {
          vm.fetching = false
        })
    })
  },
  async beforeRouteLeave(to, from, next) {
    await this.$store.dispatch('kiosk/logout')
    next()
  },
  props: {
    redirectOnInactif: {
      type: Object,
      default() {
        return { name: 'dial.index' }
      }
    },
    userPrefTimeFormat: {
      type: Object,
      default() {
        return {
          'H:i': ['H:mm'],
          'H:i T': ['H:mm'],
          'h:ia': ['h:mm', 'A'],
          'h:ia T': ['h:mm', 'A']
        }
      }
    },
    localePrefTimeFormat: {
      type: Object,
      default() {
        return {
          en: ['hh:mm', 'A'],
          fr: ['HH:mm'],
          es: ['HH:mm']
        }
      }
    }
  },
  data() {
    return {
      fetching: false,
      response: null,
      error: null,
      clock: useClock()
    }
  },
  computed: {
    currentPrefTimeFormat() {
      const currentUser = this.$store.getters['kiosk/currentUser']
      if (!currentUser) {
        return this.localePrefTimeFormat
      }
      const userTimeFormat = currentUser.pref.timeFormat

      if (!this.userPrefTimeFormat[userTimeFormat]) {
        return this.localePrefTimeFormat
      }

      return { [this.$i18n.locale]: this.userPrefTimeFormat[userTimeFormat] }
    },
    toolbarClockUser() {
      return {
        is: true,
        props: {
          value: this.clock.value,
          locale: this.$i18n.locale,
          locales: this.currentPrefTimeFormat
        }
      }
    }
  },
  watch: {
    clock: {
      immediate: true,
      deep: true,
      handler() {
        this.$emit('change:toolbar-items', {
          clock: this.toolbarClockUser
        })
      }
    }
  },
  beforeDestroy() {
    this.clock.destroy()
  }
}
</script>
