<template>
  <KioskShiftControlActionInProgressWithBreaks
    v-if="shiftControl.shiftSummary.breakCompliance.hasBreaks()"
    :shift-control="shiftControl"
    @completed="$emit('completed')"
  />
  <KioskShiftControlActionInProgressWithoutBreaks
    v-else
    :shift-control="shiftControl"
    @completed="$emit('completed')"
  />
</template>

<script>
import KioskShiftControlActionInProgressWithBreaks from './KioskShiftControlActionInProgressWithBreaks'
import KioskShiftControlActionInProgressWithoutBreaks from './KioskShiftControlActionInProgressWithoutBreaks'

export default {
  components: {
    KioskShiftControlActionInProgressWithBreaks,
    KioskShiftControlActionInProgressWithoutBreaks
  },
  inheritAttrs: false,
  props: {
    shiftControl: {
      type: Object,
      required: true
    }
  }
}
</script>
