<template>
  <div
    class="kiosk-shift-control-card cardPrimary fill-height"
    data-heap-id="kiosk_shift_control_card"
  >
    <VRow no-gutters>
      <VCol cols="12">
        <KioskShiftOwnerCard
          :shift-owner="shiftControl.shiftOwner"
          @click:signout="signout({ name: 'dial.index' })"
        />
      </VCol>
    </VRow>
    <VRow no-gutters align="stretch" class="responsive">
      <VCol cols="12">
        <VSnackbar :value="isCardActionFailed" color="error" top>
          <span v-text="cardActionErrorSnackbarText" />
        </VSnackbar>
        <KioskShiftControlCardError v-if="error" :error="error" />
        <KioskShiftControlCardScheduled
          v-else-if="shiftControl.state.matches('views.schedule')"
          :shift-control="shiftControl"
          @completed="signout({ name: 'dial.index' })"
        />
        <KioskShiftControlCardUnscheduled
          v-else-if="shiftControl.state.matches('views.unschedule')"
          :shift-control="shiftControl"
          @completed="signout({ name: 'dial.index' })"
        />
        <KioskShiftControlCardError v-else />
      </VCol>
    </VRow>
  </div>
</template>

<script>
import KioskShiftOwnerCard from '@/components/KioskShiftOwnerCard'
import KioskShiftControlCardScheduled from './KioskShiftControlCardScheduled'
import KioskShiftControlCardUnscheduled from './KioskShiftControlCardUnscheduled'
import KioskShiftControlCardError from './KioskShiftControlCardError'

export default {
  name: 'KioskShiftControlCard',
  components: {
    KioskShiftOwnerCard,
    KioskShiftControlCardScheduled,
    KioskShiftControlCardUnscheduled,
    KioskShiftControlCardError
  },
  props: {
    shiftControl: {
      type: Object,
      required: true
    },
    error: {
      type: [Object, Error],
      default: undefined
    }
  },
  computed: {
    isCardActionFailed() {
      return this.shiftControl.state.matches('action.failure')
    },
    cardActionErrorSnackbarText() {
      if (!this.isCardActionFailed) {
        return ''
      }

      const { action } = this.shiftControl.state.context
      const errorCode = action.response.code
      return this.$t(`app.kiosk.errors.${errorCode}.alert.text`)
    }
  },
  methods: {
    async signout(redirect) {
      await this.$store.dispatch('kiosk/logout')
      this.$router.push(redirect)
    }
  }
}
</script>
<style scoped>
@media (orientation: landscape) and (min-height: 800px) {
  .responsive {
    height: 74vh;
  }
}
@media (orientation: landscape) and (max-height: 799px) {
  .responsive {
    height: 65vh;
  }
}
@media (orientation: landscape) and (max-height: 600px) {
  .responsive {
    height: 85vh;
  }
}
</style>
