<template>
  <KioskShiftCard :shift-summary="shiftSummary">
    <VCol cols="12" class="pa-3">
      <VWindow v-model="main" touchless continuous>
        <VWindowItem
          v-for="i in views.length"
          :key="`view-${views[i - 1]}`"
          :value="i"
        >
          <slot v-if="views[i - 1] === 'alert'" name="alert">
            <KioskShiftCardAlert v-if="shiftSummary.alert !== ''">
              <p v-text="shiftSummary.alert" />
            </KioskShiftCardAlert>
          </slot>
          <slot v-else :name="views[i - 1]" />
        </VWindowItem>
      </VWindow>
    </VCol>
    <VCol cols="12">
      <template v-if="views.length > 1 && shiftSummary.alert !== ''">
        <VCard flat color="#f0f0f0" class="pa-0">
          <VCardActions class="pa-0">
            <VCol cols="3" class="pa-0 pl-3">
              <VIcon v-if="nextWindowName === 'alert'" color="#afafaf" small
                >info</VIcon
              >
            </VCol>
            <VCol class="pa-0">
              <VBtn
                text
                style="text-decoration: underline;"
                class="caption overline"
                color="#b3b3b3"
                :data-heap-id="`see_${nextWindowName}`"
                @click="next"
              >
                <slot v-if="nextWindowName === 'alert'" :name="`alert:button`">
                  <div class="pl-5">{{ $t('app.kiosk.seeDetails') }}</div>
                </slot>
                <slot v-else :name="`${nextWindowName}:button`">
                  {{ $t('app.kiosk.seeBreakList') }}
                </slot>
              </VBtn>
            </VCol>
          </VCardActions>
        </VCard>
      </template>
    </VCol>
  </KioskShiftCard>
</template>

<script>
import KioskShiftCard from './KioskShiftCard'
import KioskShiftCardAlert from './KioskShiftCardAlert'

export default {
  name: 'KioskShiftSummaryCard',
  components: {
    KioskShiftCard,
    KioskShiftCardAlert
  },
  props: {
    shiftSummary: {
      type: Object,
      required: true
    },
    views: {
      type: Array,
      default() {
        return ['alert', 'default']
      }
    }
  },
  data() {
    return {
      main: 1
    }
  },
  computed: {
    toggleBtn() {
      return {
        props: {
          icon: true,
          tile: true,
          block: true,
          depressed: true,
          left: true,
          outlined: true
        },
        text: this.$t('app.kiosk.seeDetails')
      }
    },
    nextIndex() {
      return this.main + 1 === this.views.length - 1 ? 0 : this.main + 1
    },
    nextWindowName() {
      return this.views[this.nextIndex - 1]
    }
  },
  methods: {
    next() {
      this.main = this.nextIndex
    }
  }
}
</script>
