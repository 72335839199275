<template functional>
  <VCard flat color="transparent" class="py-2">
    <VContainer fluid class="py-0">
      <VRow class="layout wrap fill-height">
        <VCol :xs="12" :md="4" order="1" class="py-0">
          <VRow align="center" justify="start">
            <VCol cols="auto">
              <VCard tile>
                <VImg
                  height="50"
                  width="50"
                  :src="props.shiftOwner.avatar"
                ></VImg>
              </VCard>
            </VCol>
            <VCol>
              <h3 class="body-1" v-text="props.shiftOwner.firstName" />
              <h3 class="body-1" v-text="props.shiftOwner.lastName" />
            </VCol>
          </VRow>
        </VCol>
        <VCol :xs="12" :md="5" order-sm="2" order="3" class="py-0">
          <VRow align="start" justify="center" class="fill-height" py-2>
            <VCol>
              <div
                v-if="props.shiftOwner.mobile"
                class="subheading text-capitalize"
              >
                <label
                  v-text="`${parent.$t('app.kiosk.shiftControl.mobile')} : `"
                />
                <span
                  class="font-weight-bold"
                  v-text="props.shiftOwner.mobile"
                />
              </div>
              <div
                v-if="props.shiftOwner.email"
                class="subheading text-capitalize"
              >
                <label
                  v-text="`${parent.$t('app.kiosk.shiftControl.email')} : `"
                />
                <span
                  class="font-weight-bold text-lowercase"
                  v-text="props.shiftOwner.email"
                />
              </div>
            </VCol>
          </VRow>
        </VCol>
        <VCol :xs="12" :md="3" order-sm="3" order="2" class="py-0">
          <VRow justify="end" align="start">
            <VCol cols="auto">
              <VBtn
                v-t="'app.kiosk.shiftControl.signout.btn.text'"
                outlined
                color="grey"
                data-heap-id="signout"
                @click="(event) => props.onSignout(props.shiftOwner, listeners)"
              />
            </VCol>
          </VRow>
        </VCol>
      </VRow>
    </VContainer>
  </VCard>
</template>

<script>
export default {
  name: 'KioskShiftOwnerCard',
  props: {
    shiftOwner: {
      type: Object,
      required: true
    },
    onSignout: {
      type: Function,
      default(event, listeners) {
        setTimeout(() => {
          if (!listeners['click:signout']) {
            return
          }
          listeners['click:signout'](event)
        }, 1000)
      }
    }
  }
}
</script>
