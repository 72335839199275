<template>
  <VCol class="d-flex justify-center">
    <VAvatar
      :key="`btn ${stateColor}`"
      size="195"
      :color="stateColor"
      class="elevation-6 "
      @click="onClick"
    >
      <VBtn :dark="isDark" large icon :loading="loading" class="is-circular">
        <VRow>
          <VCol cols="12" class="pb-0">
            <VIcon v-if="iconError && error" dark v-text="iconError" />
            <VIcon
              v-else-if="iconSubmitted && submitted"
              dark
              v-text="iconSubmitted"
            />
            <template v-else-if="icon">
              <slot name="icon">
                <KioskIconSvg
                  v-if="isSvg"
                  :filepath="icon"
                  :fill="iconColor"
                  size="60"
                />
                <VIcon v-else dark v-text="icon" />
              </slot>
            </template>
          </VCol>
          <VCol cols="12" class="pt-0">
            <p
              :key="`label ${stateColor}`"
              v-t="`app.kiosk.shiftControl.actions.${text}.value`"
              :class="`text-center mt-2 mb-5`"
            />
          </VCol>
        </VRow>
      </VBtn>
    </VAvatar>
  </VCol>
</template>
<script>
import KioskIconSvg from '@/components/KioskIconSvg'

export default {
  name: 'KioskShiftActionCard',
  components: {
    KioskIconSvg
  },
  props: {
    color: {
      type: String,
      default: 'primary'
    },
    colorSubmitted: {
      type: String,
      default: 'success'
    },
    colorError: {
      type: String,
      default: 'error'
    },
    icon: {
      type: String,
      default: 'primary'
    },
    iconError: {
      type: String,
      default: 'error'
    },
    iconSubmitted: {
      type: String,
      default: 'done'
    },
    iconColor: {
      type: String,
      default: 'white'
    },
    iconType: {
      type: String,
      default() {
        if (!this.icon) {
          return false
        }

        const isSvg = [
          this.icon.startsWith('img'),
          this.icon.startsWith('/img')
        ].some((value) => value)

        return isSvg ? 'svg' : 'vuetify'
      }
    },
    error: {
      type: Boolean,
      default: false
    },
    submitted: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    defaultButton: {
      type: Boolean,
      default: true
    },
    text: {
      type: String,
      required: true
    }
  },
  computed: {
    stateColor() {
      if (this.loading) {
        return this.color
      }

      if (this.error) {
        return this.colorError
      }

      if (this.submitted) {
        return this.colorSubmitted
      }

      return this.color
    },

    isSvg() {
      const stateChanged = [this.error, this.loading, this.submitted].every(
        (value) => value === false
      )

      return stateChanged ? this.iconType === 'svg' : false
    },

    isDark() {
      if (!this.defaultButton) {
        if (this.error) {
          return true
        }

        if (this.submitted) {
          return true
        }

        return false
      }
      return true
    }
  },
  methods: {
    onClick() {
      this.$emit('click')
    }
  }
}
</script>
<style scoped>
.v-btn.is-circular {
  width: 100%;
  height: 100%;
}
.v-avatar {
  cursor: pointer;
}
</style>
