<template functional>
  <VCard class="fill-height" tile>
    <VRow class="layout wrap fill-height" justify="space-between" no-gutters>
      <VCol cols="12" class="pa-5 mt-5">
        <VCard flat>
          <VCardTitle primary-title class="pa-0">
            <VCardText class="text-center">
              <slot name="title">
                <span v-if="props.title" v-text="props.title" />
              </slot>
            </VCardText>
          </VCardTitle>
        </VCard>
      </VCol>
      <VCol cols="12" class="pa-5">
        <VCard flat>
          <VRow justify="center">
            <VCol cols="11" :lg="9">
              <VRow justify="space-around">
                <slot />
              </VRow>
            </VCol>
          </VRow>
          <VCardActions class="justify-center">
            <slot name="secondary" />
          </VCardActions>
        </VCard>
      </VCol>
    </VRow>
  </VCard>
</template>

<script>
export default {
  name: 'KioskShiftActionCard'
}
</script>
