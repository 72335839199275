<template>
  <VContainer
    fluid
    class="fill-height pt-0"
    data-heap-id="kiosk_control_card_unscheduled"
  >
    <VRow
      v-if="shouldDisplayEarlyClockIn"
      class="layout wrap fill-height"
      align="stretch"
    >
      <VCol :cols="12" :md="4" :lg="3" order="1" class="pr-0 py-0">
        <KioskShiftControlCardSummary :shift-control="shiftControl" />
      </VCol>
      <VCol cols="12" :md="8" :lg="9" order="2" class="py-0">
        <KioskShiftEarlyClockInCard />
      </VCol>
    </VRow>
    <VRow v-else class="layout wrap fill-height" align="stretch">
      <VCol :cols="12">
        <KioskShiftSummaryCardError :title="$t('app.kiosk.noShift')" />
      </VCol>
    </VRow>
  </VContainer>
</template>

<script>
import KioskShiftSummaryCardError from '@/components/KioskShiftSummaryCardError'
import KioskShiftEarlyClockInCard from '@/components/KioskShiftEarlyClockInCard'
import KioskShiftControlCardSummary from './KioskShiftControlCardSummary'

export default {
  name: 'KioskShiftControlCardUnscheduled',
  components: {
    KioskShiftSummaryCardError,
    KioskShiftEarlyClockInCard,
    KioskShiftControlCardSummary
  },
  props: {
    shiftControl: {
      type: Object,
      required: true
    }
  },
  computed: {
    shouldDisplayEarlyClockIn() {
      return this.shiftControl.nextShiftSummary.isEmpty === false
    }
  }
}
</script>
