<script>
import { get } from 'lodash'

export default {
  name: 'KioskTextControlInProgress',
  inheritAttrs: false,
  props: {
    shiftControl: {
      type: Object,
      required: true
    },
    path: {
      type: String,
      required: true
    },
    default: {
      type: String,
      default: undefined
    },
    tag: {
      type: String,
      default: 'span'
    }
  },
  computed: {
    isBreakType() {
      return {
        START_BREAK_MEAL: 'meal',
        START_BREAK_REST: 'rest'
      }[this.shiftControl.state.context.action.name]
    },

    titleClockOut() {
      return this.$t('app.kiosk.shiftControl.actions.CLOCK_OUT.greeting', {
        name: this.shiftControl.shiftOwner.fullName
      })
    },

    titleStartBreak() {
      return this.$t('app.kiosk.shiftControl.actions.START_BREAK.greeting')
    },

    dialog() {
      if (this.shiftControl.shiftSummary.breakCompliance.hasBreaks()) {
        return this.dialogNextShift
      }

      return this.dialogClockOut
    },

    dialogNextShiftTitle() {
      return (type) =>
        this.$t(
          'app.kiosk.shiftControl.actions.START_BREAK.breakManagement.title',
          { typeRest: type }
        )
    },

    dialogNextShiftBreakType(type) {
      return (type) =>
        this.$t(
          `app.kiosk.shiftControl.actions.START_BREAK.breakManagement.breakType`,
          { restType: type }
        )
    },

    dialogNextShift() {
      return {
        title: this.dialogNextShiftTitle(this.isBreakType),
        body: this.dialogNextShiftBreakType(this.isBreakType)
      }
    },

    dialogClockOut() {
      return {
        title: this.$t('app.kiosk.shiftControl.actions.CLOCK_OUT.value'),
        body: this.$t('app.kiosk.shiftControl.actions.CLOCK_OUT.value')
      }
    }
  },
  render(h) {
    const content = this.path
      .split('.')
      .reduce((content, path) => get(content, path, this.default), this)

    if (this.$scopedSlots.default) {
      return this.$scopedSlots.default({ text: content })
    }

    return h(this.tag, this.$attrs, [content])
  }
}
</script>
