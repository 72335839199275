<template>
  <KioskShiftActionCard data-heap-id="kiosk_control_action_scheduled">
    <template #title>
      <KioskShiftTextScheduled
        :class="'headline'"
        :tag="'h3'"
        :shift-control="shiftControl"
        path="title"
      />
    </template>
    <KioskShiftControlAction name="CLOCK_IN" :shift-control="shiftControl">
      <template #default="action">
        <KioskCircularBtn
          v-bind="action.attrs"
          :icon="action.meta.icon"
          :loading="action.running"
          :submitted="action.submitted"
          :error="action.error"
          :text="'CLOCK_IN'"
          @click="() => action.trigger()"
        />
      </template>
      <template #success="action">
        <KioskShiftDialog
          :name="action.name"
          :value="{ show: true }"
          :icon="'clockin'"
          :confirm="false"
          @close="$emit('completed')"
        >
          <template #title>
            <KioskShiftTextScheduled
              :shift-control="shiftControl"
              path="dialog.title"
              :class="'display-1 primary--text'"
              :tag="'h3'"
            />
          </template>
          <template #default>
            <KioskShiftTextScheduled
              :shift-control="shiftControl"
              path="dialog.body"
              :class="'body-1'"
              :tag="'p'"
            />
          </template>
        </KioskShiftDialog>
      </template>
    </KioskShiftControlAction>
  </KioskShiftActionCard>
</template>
<script>
import KioskShiftActionCard from '@/components/KioskShiftActionCard'
import KioskCircularBtn from '@/components/KioskCircularBtn'
import KioskShiftDialog from '@/components/KioskShiftDialog'
import KioskShiftControlAction from './KioskShiftControlAction'
import KioskShiftTextScheduled from './KioskShiftTextScheduled'

export default {
  components: {
    KioskShiftControlAction,
    KioskShiftActionCard,
    KioskCircularBtn,
    KioskShiftDialog,
    KioskShiftTextScheduled
  },
  inheritAttrs: false,
  props: {
    shiftControl: {
      type: Object,
      required: true
    }
  }
}
</script>
