<script>
import moment from 'moment'
import { get } from 'lodash'

const formatBreakSchedule = (shiftStart, breakStart) => {
  return moment(shiftStart)
    .add(breakStart, 'm')
    .format('LT')
}

export default {
  name: 'KioskShiftTextScheduled',
  inheritAttrs: false,
  props: {
    shiftControl: {
      type: Object,
      required: true
    },
    path: {
      type: String,
      required: true
    },
    default: {
      type: String,
      default: undefined
    },
    tag: {
      type: String,
      default: 'span'
    }
  },
  computed: {
    shouldDisplayBreak() {
      const { breakCompliance } = this.shiftControl.shiftSummary
      const nextBreakSegmentItem = breakCompliance.findNextBreakComplianceItems()
      return nextBreakSegmentItem !== undefined
    },

    dialogBreakTitle() {
      return this.$t('app.kiosk.shiftControl.actions.CLOCK_IN.success.title')
    },

    dialogBreakRange() {
      return (type, startHour, endHour) =>
        this.$t(
          `app.kiosk.shiftControl.actions.CLOCK_IN.breakManagement.breakRange`,
          {
            restType: type,
            startHour: startHour,
            endHour: endHour
          }
        )
    },

    dialogBreakAt() {
      return (type, hour) =>
        this.$t(
          `app.kiosk.shiftControl.actions.CLOCK_IN.breakManagement.breakAt`,
          {
            restType: type,
            hour: hour
          }
        )
    },

    dialogBreak() {
      const { startDateTime, breakCompliance } = this.shiftControl.shiftSummary
      const nextBreakSegmentItem = breakCompliance.findNextBreakComplianceItems()
      const { offsetEnd, offsetStart, type } = nextBreakSegmentItem
      const startBreakTime = formatBreakSchedule(startDateTime, offsetStart)
      if (breakCompliance.isRangeBreakCondition(nextBreakSegmentItem)) {
        const endBreakTime = formatBreakSchedule(startDateTime, offsetEnd)
        return {
          title: this.dialogBreakTitle,
          body: this.dialogBreakRange(
            type.toLowerCase(),
            startBreakTime,
            endBreakTime
          )
        }
      }
      return {
        title: this.dialogBreakTitle,
        body: this.dialogBreakAt(type.toLowerCase(), startBreakTime)
      }
    },

    dialogWithoutBreak() {
      return {
        title: this.dialogBreakTitle,
        body: this.$tc(
          `app.kiosk.shiftControl.actions.CLOCK_IN.success.body`,
          this.shiftControl.shiftSummary.breakInMinutes,
          { count: this.shiftControl.shiftSummary.breakInMinutes }
        )
      }
    },

    dialog() {
      return this.shouldDisplayBreak
        ? this.dialogBreak
        : this.dialogWithoutBreak
    },
    title() {
      return this.$t('app.kiosk.shiftControl.actions.CLOCK_IN.greeting')
    }
  },
  render(h) {
    const content = this.path
      .split('.')
      .reduce((content, path) => get(content, path, this.default), this)

    if (this.$scopedSlots.default) {
      return this.$scopedSlots.default({ text: content })
    }

    return h(this.tag, this.$attrs, [content])
  }
}
</script>
