<script>
export default {
  name: 'KioskAppRedirect',
  inheritAttrs: false,
  props: {
    route: {
      type: [Object, Function],
      required: true
    }
  },
  mounted() {
    if (typeof this.route === typeof Function()) {
      return this.$router.push(this.route())
    } else {
      this.$router.push(this.route)
    }
  },
  render() {
    return null
  }
}
</script>
