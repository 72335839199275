import {
  useShiftControl,
  SHIFT_ACTIONS,
  withControlAction,
  ShiftOwner,
  ShiftSummary
} from '@/lib/kiosk'

export default {
  name: 'KioskShiftControlProvider',
  data() {
    const service = useShiftControl(this).onTransition((state) => {
      if (state.changed) {
        this.state = state
      }
    })

    return {
      service: service,
      state: service.initialState
    }
  },
  created() {
    this.service.start()

    this.service.send('SHIFT.UPDATE', {
      payload: this.$store.getters['kiosk/currentUser']
    })
  },
  computed: {
    shiftActions() {
      const { nextEvents } = this.service.state

      return nextEvents
        .filter((eventKey) => eventKey.startsWith('SHIFT_ACTION.'))
        .map((eventKey) => eventKey.substring('SHIFT_ACTION.'.length))
        .filter((actionKey) => SHIFT_ACTIONS[actionKey])
        .reduce((actions, actionKey) => {
          const Action = withControlAction(SHIFT_ACTIONS[actionKey])
          actions[actionKey] = new Action((payload, action) =>
            this.$triggerAction(action, payload)
          )
          return actions
        }, {})
    },
    shiftOwner() {
      const { user } = this.state.context
      return ShiftOwner.fromPayload({ user })
    },
    shiftSummary() {
      const { shift } = this.state.context
      return ShiftSummary.fromPayload(shift)
    },
    nextShiftSummary() {
      const { nextShift } = this.state.context
      return ShiftSummary.fromPayload(nextShift)
    },
    startGracePeriodSummary() {
      return this.shiftSummary.startGracePeriodInMinutes
    }
  },
  methods: {
    async $triggerAction(action, payload) {
      if (this.service.state.matches('action.running')) {
        return
      }

      this.service.send({
        type: `SHIFT_ACTION.${action.name}`,
        payload,
        action: {
          ...action,
          trigger: (payload) => {
            return this.$store.dispatch(`kiosk/${action.name}`, payload)
          }
        }
      })
    }
  },
  render(h) {
    if (this.$slots.default) {
      return this.$slots.default
    }

    return this.$scopedSlots.default({
      state: this.state,
      service: this.service,
      shiftOwner: this.shiftOwner,
      shiftActions: this.shiftActions,
      shiftSummary: this.shiftSummary,
      nextShiftSummary: this.nextShiftSummary,
      startGracePeriodSummary: this.startGracePeriodSummary
    })
  }
}
