<template>
  <VContainer class="py-0">
    <VRow align="start">
      <VCol cols="auto" class="pr-1 pt-2">
        <slot v-if="icon && icon !== false" name="action">
          <KioskIconSvg :filepath="icon" :fill="iconColor" size="14" />
        </slot>
      </VCol>
      <VCol cols="10" class="px-0">
        <slot>
          <p class="ma-0 caption" v-text="address" />
          <label class="caption" v-text="`${$t('app.kiosk.position')} : `" />
          <span class="caption" v-text="text" />
        </slot>
      </VCol>
    </VRow>
  </VContainer>
</template>

<script>
import KioskIconSvg from './KioskIconSvg'

export default {
  name: 'KioskAddressTile',
  components: { KioskIconSvg },
  inheritAttrs: false,
  props: {
    text: {
      type: String,
      required: true
    },
    address: {
      type: String,
      required: true
    },
    icon: {
      type: String,
      default: ''
    },
    iconColor: {
      type: String,
      default: 'grey'
    }
  }
}
</script>
