<template>
  <KioskShiftActionCard data-heap-id="kiosk_control_action_on_break">
    <template #title>
      <KioskShiftTextOnBreak
        :tag="'h3'"
        :class="'headline'"
        :shift-control="shiftControl"
        path="title"
      />
    </template>
    <template #default>
      <KioskShiftControlAction name="END_BREAK" :shift-control="shiftControl">
        <template #default="action">
          <KioskCircularBtn
            v-bind="action.attrs"
            :icon="action.meta.icon"
            :loading="action.running"
            :submitted="action.submitted"
            :text="'END_BREAK'"
            @click="() => action.trigger({ type: 'rest' })"
          />
        </template>
        <template #success="action">
          <KioskShiftDialog
            :name="action.name"
            :value="{ show: true }"
            :icon="'warning'"
            :confirm="false"
            @close="$emit('completed')"
          >
            <template #title>
              <KioskShiftTextOnBreak
                :shift-control="shiftControl"
                path="dialog.title"
                :class="'display-1 primary--text text-capitalize'"
                :tag="'h3'"
              />
            </template>
            <template #default>
              <KioskShiftTextOnBreak
                :shift-control="shiftControl"
                path="dialog.body"
                :class="'body-1'"
                :tag="'p'"
              />
            </template>
          </KioskShiftDialog>
        </template>
      </KioskShiftControlAction>
    </template>
  </KioskShiftActionCard>
</template>
<script>
import KioskShiftActionCard from '@/components/KioskShiftActionCard'
import KioskCircularBtn from '@/components/KioskCircularBtn'
import KioskShiftDialog from '@/components/KioskShiftDialog'
import KioskShiftControlAction from './KioskShiftControlAction'
import KioskShiftTextOnBreak from './KioskShiftTextOnBreak'

export default {
  components: {
    KioskShiftControlAction,
    KioskShiftActionCard,
    KioskCircularBtn,
    KioskShiftDialog,
    KioskShiftTextOnBreak
  },
  inheritAttrs: false,
  props: {
    shiftControl: {
      type: Object,
      required: true
    }
  }
}
</script>
