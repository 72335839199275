<template>
  <KioskShiftSummaryCardError
    v-if="isScheduledAtDifferentLocation"
    :icon="'warning'"
    :title="$t('app.kiosk.scheduledAtDifferentAccount')"
  />
  <KioskShiftSummaryCardError
    v-else
    :icon="'warning'"
    :title="$t('app.kiosk.errors.default.alert.text')"
  />
</template>

<script>
import KioskShiftSummaryCardError from '@/components/KioskShiftSummaryCardError'
import * as KioskShiftControlErrors from './errors'

export default {
  name: 'KioskShiftControlCardError',
  components: {
    KioskShiftSummaryCardError
  },
  props: {
    error: {
      type: [Object, Error],
      required: true
    }
  },
  computed: {
    isScheduledAtDifferentLocation() {
      return this.matches(
        KioskShiftControlErrors.KIOSK_SHIFT_SCHEDULED_AT_DIFFERENT_LOCATION_BRV_000008
      )
    }
  },
  methods: {
    matches(codeSearched) {
      const codesArray = Array.isArray(codeSearched)
        ? codeSearched
        : [codeSearched]
      const { code } = this.error || {}
      return codesArray.includes(code)
    }
  }
}
</script>
