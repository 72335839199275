<template>
  <KioskShiftActionCard data-heap-id="kiosk_control_action_with_breaks">
    <template #title>
      <KioskShiftTextInProgress
        :class="'headline'"
        :tag="'h3'"
        :shift-control="shiftControl"
        path="titleStartBreak"
      />
    </template>
    <KioskGuardBreakManagementRequired>
      <template #default>
        <VRow justify="center">
          <VCol cols="6">
            <KioskShiftControlAction
              name="START_BREAK_MEAL"
              :shift-control="shiftControl"
            >
              <template #default="action">
                <KioskCircularBtn
                  v-bind="action.attrs"
                  :icon="action.meta.icon"
                  :icon-color="'primary'"
                  :text="'MEAL_BREAK'"
                  :color="'white'"
                  :loading="action.running"
                  :error="action.error"
                  :submitted="action.submitted"
                  :default-button="false"
                  @click="() => action.trigger()"
                />
              </template>
              <template #before="action">
                <KioskShiftDialog
                  :name="action.name"
                  :value="{ show: true }"
                  :icon="'meal'"
                  :close="action.cancel"
                  :confirm="action.continue"
                  :cancel="action.cancel"
                  @close="action.cancel"
                >
                  <template #title>
                    <KioskShiftTextInProgress
                      :shift-control="shiftControl"
                      path="dialogNextShift.title"
                      :class="'display-1 primary--text text-capitalize'"
                      :tag="'h3'"
                    />
                  </template>
                  <template #default>
                    <KioskShiftTextInProgress
                      :shift-control="shiftControl"
                      :path="'dialogNextShift.body'"
                      :class="'body-1'"
                      :tag="'p'"
                    />
                  </template>
                </KioskShiftDialog>
              </template>
              <template #success="action">
                <KioskAppRedirect :route="{ name: 'dial.index' }" />
              </template>
            </KioskShiftControlAction>
          </VCol>
          <VCol cols="6">
            <KioskShiftControlAction
              name="START_BREAK_REST"
              :shift-control="shiftControl"
            >
              <template #default="action">
                <KioskCircularBtn
                  v-bind="action.attrs"
                  :icon="action.meta.icon"
                  :icon-color="'primary'"
                  :text="'REST_BREAK'"
                  :loading="action.running"
                  :color="'white'"
                  :submitted="action.submitted"
                  :error="action.error"
                  :default-button="false"
                  @click="() => action.trigger()"
                />
              </template>
              <template #before="action">
                <KioskShiftDialog
                  :name="action.name"
                  :value="{ show: true }"
                  :icon="'rest'"
                  :close="action.cancel"
                  :confirm="action.continue"
                  :cancel="action.cancel"
                  @close="action.cancel"
                >
                  <template #title>
                    <KioskShiftTextInProgress
                      :shift-control="shiftControl"
                      path="dialogNextShift.title"
                      :class="'display-1 primary--text text-capitalize'"
                      :tag="'h3'"
                    />
                  </template>
                  <template #default>
                    <KioskShiftTextInProgress
                      :shift-control="shiftControl"
                      :path="'dialogNextShift.body'"
                      :class="'body-1'"
                      :tag="'p'"
                    />
                  </template>
                </KioskShiftDialog>
              </template>
              <template #success="action">
                <KioskAppRedirect :route="{ name: 'dial.index' }" />
              </template>
            </KioskShiftControlAction>
          </VCol>
        </VRow>
      </template>
      <template #rejected>
        <KioskShiftControlAction
          name="START_BREAK"
          :shift-control="shiftControl"
        >
          <template #default="action">
            <KioskCircularBtn
              v-bind="action.attrs"
              :icon="action.meta.icon"
              :loading="action.running"
              :submitted="action.submitted"
              :error="action.error"
              @click="() => action.trigger()"
            />
          </template>
          <template #success="action">
            <KioskShiftDialog
              :name="action.name"
              :value="{ show: true }"
              :confirm="false"
              @close="$emit('completed')"
            />
          </template>
        </KioskShiftControlAction>
      </template>
    </KioskGuardBreakManagementRequired>
    <template #secondary>
      <KioskShiftControlAction name="CLOCK_OUT" :shift-control="shiftControl">
        <template #default="action">
          <KioskShiftActionBtn
            :data-heap-id="`action_${action.name}`"
            v-bind="action.attrs"
            color="grey"
            :loading="action.running"
            outlined
            @click="action.trigger"
          >
            <span v-t="`app.kiosk.shiftControl.actions.CLOCK_OUT.value`" />
          </KioskShiftActionBtn>
        </template>
        <template #success="action">
          <KioskShiftControlAction
            name="CLOCK_OUT"
            :shift-control="shiftControl"
          >
            <KioskShiftDialog
              :name="action.name"
              :icon="'clockout'"
              v-bind="dialogShiftComplianceProps"
              persistent
              :close="false"
              :confirm-text="
                $t('app.kiosk.shiftControl.actions.DISAPPROVE_CLOCKOUT.confirm')
              "
              :cancel-text="
                $t('app.kiosk.shiftControl.actions.DISAPPROVE_CLOCKOUT.cancel')
              "
              width="650px"
              @close="dialogShiftComplianceProps.close"
            >
              <template #title>
                <KioskShiftTextInProgress
                  :shift-control="shiftControl"
                  path="dialogClockOut.title"
                  :class="'display-1 primary--text text-capitalize'"
                  :tag="'h3'"
                />
              </template>
              <template #default>
                <VCardTitle
                  v-t="
                    'app.kiosk.shiftControl.actions.DISAPPROVE_CLOCKOUT.subtitle'
                  "
                />
                <KioskShiftComplianceTable
                  :shift="shiftControl.shiftSummary.shift"
                />
                <div class="error--text">
                  <VIcon color="error" v-text="'warning'" />
                  <span
                    v-t="
                      'app.kiosk.shiftControl.actions.DISAPPROVE_CLOCKOUT.warn'
                    "
                  />
                </div>
              </template>
            </KioskShiftDialog>
          </KioskShiftControlAction>
          <KioskShiftDialog
            :name="action.name"
            v-bind="dialogNextShiftProps"
            @close="dialogNextShiftProps.close"
          >
            <template #title>
              <KioskShiftTextInProgress
                :shift-control="shiftControl"
                path="dialogClockOut.title"
                :class="'display-1 primary--text text-capitalize'"
                :tag="'h3'"
              />
            </template>
            <template #extension>
              <KioskShiftCard
                v-if="shiftControl.nextShiftSummary.isEmpty === false"
                outlined
                :shift-summary="shiftControl.nextShiftSummary"
              />
              <KioskShiftSummaryCardError
                v-else
                outlined
                height="'200px'"
                :title="$t('app.kiosk.noShift')"
                flat
              />
            </template>
          </KioskShiftDialog>
        </template>
      </KioskShiftControlAction>
    </template>
  </KioskShiftActionCard>
</template>
<script>
import KioskGuardBreakManagementRequired from '@/guards/components/KioskGuardBreakManagementRequired'
import KioskShiftComplianceTable from '@/components/KioskShiftComplianceTable'
import KioskAppRedirect from '@/components/KioskAppRedirect'
import KioskShiftCard from '@/components/KioskShiftCard'
import KioskShiftActionCard from '@/components/KioskShiftActionCard'
import KioskShiftActionBtn from '@/components/KioskShiftActionBtn'
import KioskShiftSummaryCardError from '@/components/KioskShiftSummaryCardError'
import KioskCircularBtn from '@/components/KioskCircularBtn'
import KioskShiftDialog from '@/components/KioskShiftDialog'
import KioskShiftControlAction from './KioskShiftControlAction'
import KioskShiftTextInProgress from './KioskShiftTextInProgress'

export default {
  components: {
    KioskGuardBreakManagementRequired,
    KioskAppRedirect,
    KioskShiftControlAction,
    KioskShiftActionCard,
    KioskShiftCard,
    KioskCircularBtn,
    KioskShiftActionBtn,
    KioskShiftDialog,
    KioskShiftTextInProgress,
    KioskShiftComplianceTable,
    KioskShiftSummaryCardError
  },
  inheritAttrs: false,
  props: {
    shiftControl: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      dialogs: ['shift-compliance', 'next-shift']
    }
  },
  computed: {
    shouldRenderShiftComplianceDialog() {
      return this.dialogs[0] === 'shift-compliance'
    },
    shouldRenderNextShiftDialog() {
      return this.dialogs[0] === 'next-shift'
    },
    dialogShiftComplianceProps() {
      const { dialogs } = this

      return {
        value: {
          show: this.shouldRenderShiftComplianceDialog
        },
        close() {
          dialogs.shift()
        },
        confirm: async () => {
          return true
        },
        cancel: async () => {
          try {
            await this.$store.dispatch('kiosk/disapproveShift')
          } catch (err) {
            this.$log.error(err)
          }
        }
      }
    },
    dialogNextShiftProps() {
      return {
        value: {
          show: this.shouldRenderNextShiftDialog
        },
        close: () => {
          this.$emit('completed')
        }
      }
    }
  }
}
</script>
