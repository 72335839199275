<script>
import KioskIconSvg from './KioskIconSvg'

export default {
  name: 'KioskShiftActionBtn',
  functional: true,
  inheritAttrs: false,
  props: {
    icon: {
      type: String,
      default: undefined
    }
  },
  render(h, context) {
    const { icon } = context.props
    const { attrs } = context.data

    const createIcon = () => {
      if (!icon) {
        return null
      }

      if (icon.endsWith('svg')) {
        return h(KioskIconSvg, {
          props: {
            filepath: icon,
            size: '20'
          }
        })
      }

      return h(
        'VIcon',
        {
          props: {
            left: true
          }
        },
        [icon]
      )
    }

    const { $toHeapId, $heapOptions } = context.parent.$root
    const { prop: heapPropKey, attr: heapAttrKey } = $heapOptions

    const btnProps = { ...attrs }
    const btnAttrs = {}

    if (context.props[heapPropKey]) {
      btnAttrs[heapAttrKey] = $toHeapId(context.props[heapPropKey])
    }

    return h(
      'VBtn',
      {
        on: context.listeners,
        props: btnProps,
        attrs: btnAttrs
      },
      [createIcon(), context.slots().default]
    )
  }
}
</script>
