<template>
  <KioskShiftActionCard data-heap-id="kiosk_control_action_without_breaks">
    <template #title>
      <KioskShiftTextInProgress
        :class="'headline'"
        :tag="'h3'"
        :shift-control="shiftControl"
        path="titleClockOut"
      />
    </template>
    <template #default="action">
      <KioskShiftControlAction
        v-if="shiftControl.shiftSummary.breakInMinutes === 0"
        name="CLOCK_OUT"
        :shift-control="shiftControl"
      >
        <template #default="action">
          <KioskCircularBtn
            v-bind="action.attrs"
            :icon="action.meta.icon"
            :loading="action.running"
            :submitted="action.submitted"
            :error="action.error"
            :text="'CLOCK_OUT'"
            @click="() => action.trigger()"
          />
        </template>
        <template #success="action">
          <KioskShiftDialog
            :name="action.name"
            :value="{ show: true }"
            @close="$emit('completed')"
          >
            <template #title>
              <KioskShiftTextInProgress
                :shift-control="shiftControl"
                path="dialogClockOut.title"
                :class="'display-1 pl-2 primary--text text-capitalize'"
                :tag="'h3'"
              />
            </template>
            <template #default>
              <KioskShiftCard
                v-if="shiftControl.nextShiftSummary.isEmpty === false"
                outlined
                :shift-summary="shiftControl.nextShiftSummary"
              />
              <KioskShiftSummaryCardError
                v-else
                outlined
                height="'200px'"
                :title="$t('app.kiosk.noShift')"
                flat
              />
            </template>
          </KioskShiftDialog>
        </template>
      </KioskShiftControlAction>
      <KioskShiftControlAction
        v-else
        name="START_BREAK"
        :shift-control="shiftControl"
      >
        <template #default="action">
          <KioskCircularBtn
            v-bind="action.attrs"
            :icon="action.meta.icon"
            :loading="action.running"
            :submitted="action.submitted"
            :error="action.error"
            :text="'START_BREAK'"
            @click="() => action.trigger()"
          />
        </template>
        <template #success="action">
          <KioskAppRedirect :route="{ name: 'dial.index' }" />
        </template>
      </KioskShiftControlAction>
    </template>
    <template #secondary>
      <KioskShiftControlAction
        v-if="shiftControl.shiftSummary.breakInMinutes > 0"
        name="CLOCK_OUT"
        :shift-control="shiftControl"
      >
        <template #default="action">
          <KioskShiftActionBtn
            color="grey"
            :data-heap-id="`action_${action.name}`"
            :loading="action.running"
            outlined
            @click="action.trigger"
          >
            <span v-t="`app.kiosk.shiftControl.actions.${action.name}.value`" />
          </KioskShiftActionBtn>
        </template>
        <template #success="action">
          <KioskShiftDialog
            :name="action.name"
            :value="{ show: true }"
            @close="$emit('completed')"
          >
            <template #title>
              <KioskShiftTextInProgress
                :shift-control="shiftControl"
                path="dialogClockOut.title"
                :class="'display-1 pl-2 primary--text text-capitalize'"
                :tag="'h3'"
              />
            </template>
            <template #default>
              <KioskShiftCard
                v-if="shiftControl.nextShiftSummary.isEmpty === false"
                outlined
                :shift-summary="shiftControl.nextShiftSummary"
              />
              <KioskShiftSummaryCardError
                v-else
                outlined
                height="'200px'"
                :title="$t('app.kiosk.noShift')"
                flat
              />
            </template>
          </KioskShiftDialog>
        </template>
      </KioskShiftControlAction>
    </template>
  </KioskShiftActionCard>
</template>
<script>
import KioskShiftActionCard from '@/components/KioskShiftActionCard'
import KioskCircularBtn from '@/components/KioskCircularBtn'
import KioskShiftDialog from '@/components/KioskShiftDialog'
import KioskShiftControlAction from './KioskShiftControlAction'
import KioskShiftTextInProgress from './KioskShiftTextInProgress'
import KioskShiftCard from '@/components/KioskShiftCard'
import KioskShiftSummaryCardError from '@/components/KioskShiftSummaryCardError'
import KioskShiftActionBtn from '@/components/KioskShiftActionBtn'
import KioskAppRedirect from '@/components/KioskAppRedirect'

export default {
  components: {
    KioskAppRedirect,
    KioskShiftActionBtn,
    KioskShiftCard,
    KioskShiftControlAction,
    KioskShiftActionCard,
    KioskCircularBtn,
    KioskShiftDialog,
    KioskShiftTextInProgress,
    KioskShiftSummaryCardError
  },
  inheritAttrs: false,
  props: {
    shiftControl: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      dialogs: ['shift-compliance', 'next-shift']
    }
  }
}
</script>
