<template functional>
  <VCard v-bind="data.attrs" class="fill-height" tile>
    <VContainer fluid fill-height>
      <VRow align="center" justify="center">
        <VCol cols="auto" align-self="center">
          <VAvatar size="85" color="primary">
            <VIcon class="text-center" large dark v-text="props.icon" />
          </VAvatar>
        </VCol>
        <VCol cols="12">
          <h1 class="text-center headline" v-text="props.title" />
        </VCol>
      </VRow>
    </VContainer>
  </VCard>
</template>

<script>
export default {
  name: 'KioskShiftSummaryCardError',
  inheritAttrs: false,
  props: {
    icon: {
      type: String,
      default: 'beach_access'
    },
    title: {
      type: String,
      default() {
        return ''
      }
    }
  }
}
</script>
